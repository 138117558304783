import React from "react";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";

export const LeftArrow = (props) => {
  return (
    <div {...props} className="absolute slider-arrows top-[50%] left-0">
      <TiChevronLeft size={27} />
    </div>
  );
};
export const RightArrow = (props) => {
  return (
    <div {...props} className="absolute slider-arrows top-[50%] right-0 ">
      <TiChevronRight size={27} />
    </div>
  );
};
