import React from "react";
import Button from "../common/buttons/Button";

const VacationExperience = ({
  data,
  headingSize = "text-3xl sm:text-[40px]",
  show = "show",
}) => {
  return (
    <div
      className={`relative max-w-[1140px] px-3 sm:px-0 w-full mx-auto box-border ${show}`}
    >
      <div className="w-full min-h-[90vh] flex flex-wrap flex-col justify-center p-[10px] sm:p-5 box-border">
        <h1
          className={`${headingSize} pt-5 pb-[10px] font-bold text-[#666666] text-center mb-3`}
        >
          {data?.heading}
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5 gap-y-5 pt-5 pb-10 max-w-full mx-auto">
          {data?.sliderImages?.map((item, index) => {
            return (
              <div
                className="flex flex-col flex-wrap justify-start col-span-1 row-span-1 rounded-[5px] 
          transition-transform duration-200 ease-linear box-border overflow-hidden"
                style={{
                  boxShadow: "0px 0px 18px rgb(0 0 0 / 50%)",
                }}
                key={index}
              >
                {/* Image Container */}
                {item?.sectionBg?.asset?.url && (
                  <div className="block h-[120px] sm:h-[150px]">
                    <img
                      src={item?.sectionBg?.asset?.url}
                      alt="Card Image"
                      loading="lazy"
                      className="w-full h-full"
                    />
                  </div>
                )}
                {/* Text Container */}

                <div className="py-5 px-[10px]">
                  <h1 className="text-[26px] pt-5 sm:text-3xl text-[#666666] font-bold uppercase text-center mb-3">
                    {item.heading}
                  </h1>

                  <h2 className="text-xl text-center pb-5 mb-3 font-semibold tracking-[2px] text-[#666666]">
                    {item.subHeading}
                  </h2>
                </div>
              </div>
            );
          })}
        </div>

        {/* Buttons Container */}
        <div className="w-[95%] mx-auto">
          <div className="flex flex-row flex-wrap max-w-full align-top items-stretch justify-around">
            {/* Button 1 */}
            {data?.ctaButton1?.title && (
              <div
                className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
              flex flex-col flex-wrap justify-start relative"
              >
                <div className="relative block pt-5 pb-0 sm:pb-10 m-[1px]">
                  <Button
                    btnText={data?.ctaButton1?.title}
                    btnLink={data?.ctaButton1?.link}
                    btnType={data?.ctaButton1?.variant}
                    linkType={data?.ctaButton1?.type}
                    formId={data?.ctaButton1?.formId}
                  />
                </div>
              </div>
            )}
            {/* Button 2 */}
            {data?.ctaButton2?.title && (
              <div
                className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
              flex flex-col flex-wrap justify-start relative"
              >
                <div className="relative block pt-5 pb-0 sm:pb-10 m-[1px]">
                  <Button
                    btnText={data?.ctaButton2?.title}
                    btnLink={data?.ctaButton2?.link}
                    btnType={data?.ctaButton2?.variant}
                    linkType={data?.ctaButton2?.type}
                    formId={data?.ctaButton2?.formId}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacationExperience;
