import React, { useState } from "react";
import PortableText from "react-portable-text";

const DetailsPolicies = ({ h1Size, data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleChangeOption(index) {
    setSelectedIndex(index);
  }

  return (
    <div className="relative block box-border pb-10 show">
      <div className="relative max-w-[1140px] w-full mx-auto">
        <div className="flex flex-col flex-wrap justify-center p-[10px] sm:p-5 min-h-[90vh]">
          <div className="block box-border">
            <div className="block w-full p-[10px] sm:p-5">
              <div className="relative block w-full pt-5 pb-[10px]">
                <h1
                  className={`${h1Size} font-bold text-center mb-3 uppercase`}
                >
                  {data?.title}
                </h1>
              </div>
              {/* Buttons */}
              <div className="block w-full mx-auto text-center">
                {data?.item?.map((item, index) => (
                  <button
                    key={index}
                    className={`inline-block py-[2px] px-[10px] m-[6px] text-sm sm:text-base uppercase rounded-[5px] text-center no-underline 
                whitespace-nowrap border border-solid border-[#ccc]
                box-border transition-all duration-200 ease-linear sm:tracking-[1px] ${
                  selectedIndex === index
                    ? "bg-primary text-white"
                    : "bg-[#cccccc] hover:text-primary"
                } `}
                    onClick={() => handleChangeOption(index)}
                    aria-label={item?.title}
                  >
                    {item.title}
                  </button>
                ))}
              </div>
              {/* Container */}
              {data?.item.map((item, index) => {
                if (selectedIndex === index) {
                  return (
                    <div key={index} className="relative block p-3 g-scaleIn">
                      <div className="flex flex-col justify-center p-[10px] sm:p-5 min-h-[50vh] sm:min-h-[90vh]">
                        <p className="text-[20px] sm:text-3xl text-assent font-bold uppercase text-center mb-3 leading-10 tracking-[2px]">
                          {item?.title}
                        </p>
                        {item.rows.map((row, ind) => {
                          return (
                            <div
                              key={ind}
                              className={`grid grid-cols-1 ${
                                row?.columns === 2
                                  ? "sm:grid-cols-2 text-center"
                                  : ""
                              }${
                                row?.columns === 3 ? "sm:grid-cols-3" : ""
                              } gap-6 mb-5 `}
                            >
                              {row?.columnItem.map((i, n) => {
                                if (i?._rawCol || i?.col)
                                  return (
                                    <PortableText
                                      key={n}
                                      content={i?._rawCol || i?.col}
                                      className={`portable-editor ${
                                        i?.alignment ? i.alignment : "text-left"
                                      } text-assent font-bold block-content max-w-none text-sm sm:text-base`}
                                      serializers={{
                                        ul: ({ children }) => (
                                          <ul className="list-none">
                                            {children}
                                          </ul>
                                        ),
                                        li: ({ children }) => (
                                          <li className="mx-0 ml-3 my-1 before:content-[''] before:h-[8px] before:w-[8px] before:rounded-full before:border before:border-[#ccc] before:inline-block before:mr-4">
                                            {children}
                                          </li>
                                        ),
                                      }}
                                    />
                                  );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPolicies;
