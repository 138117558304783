import React, { Fragment } from "react";
import { graphql } from "gatsby";
import PageBuilder from "../components/pageBuilder";
import Layout from "../components/layouts";
import Seo from "../components/seo";

export default function Page({ data: { sanitySitesettings }, pageContext }) {
  const { page } = pageContext;

  return (
    <Layout>
      {page?.seo && <Seo data={page.seo} />}
      {page?._rawPageEditor && (
        <PageBuilder
          data={page._rawPageEditor}
          logo={sanitySitesettings?.logo}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  {
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
