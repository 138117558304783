import React from "react";
import Button from "../common/buttons/Button";
import PortableText from "react-portable-text";

const Review = ({ data }) => {
  return (
    <div
      className="relative flex flex-col justify-center overflow-hidden box-border bg-cover bg-repeat-x show min-h-[90vh]"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(102, 102, 102, 0.90),rgba(214, 214, 214, 0.90)),url(${data?.sectionBg?.asset?.url})`,
      }}
    >
      <div className="relative max-w-[1140px] mx-auto">
        {/* Section which will be used other components also */}
        <div className="relative py-5 px-[10px] min-h-[80vh] flex flex-col justify-center box-border">
          <h1 className="text-3xl sm:text-6xl text-white text-center pb-5  mb-3 font-bold tracking-[2px]">
            {data?.heading}
          </h1>

          <h2 className="text-xl sm:text-3xl pb-5 text-white text-center mb-3 font-bold tracking-[2px]">
            {data?.subHeading}
          </h2>

          <h3 className="text-xl sm:text-3xl pb-5 text-white text-center mb-3 font-bold tracking-[2px]">
            {data?.subHeading2}
          </h3>

          {/* Paragraph */}

          <p className="text-base w-3/4 mx-auto sm:text-lg text-left text-white font-bold">
            "{data?.comment}"
          </p>

          {data?.author && (
            <PortableText
              content={data.author}
              className="block-content w-3/4 mx-auto text-right text-white my-6 text-sm sm:text-lg font-semibold max-w-none"
            />
          )}

          <div className="w-[95%] mx-auto flex flex-row flex-wrap max-w-full align-top items-stretch justify-around">
            {/* Button 1 */}
            {data?.ctaButton1?.title && (
              <div
                className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
                flex flex-col flex-wrap justify-start"
              >
                <div className="relative block pt-5 pb-0 sm:pb-10 m-[1px]">
                  <Button
                    btnText={data?.ctaButton1?.title}
                    btnLink={data?.ctaButton1?.link}
                    btnType={data?.ctaButton1?.variant}
                    linkType={data?.ctaButton1?.type}
                    formId={data?.ctaButton1?.formId}
                  />
                </div>
              </div>
            )}
            {/* Button 2 */}
            {data?.ctaButton2?.title && (
              <div
                className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
                flex flex-col flex-wrap justify-start"
              >
                <div className="relative block pt-5 pb-0 sm:pb-10 m-[1px]">
                  <Button
                    btnText={data?.ctaButton2?.title}
                    btnLink={data?.ctaButton2?.link}
                    btnType={data?.ctaButton2?.variant}
                    linkType={data?.ctaButton2?.type}
                    formId={data?.ctaButton2?.formId}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
