import React from "react";
import Slider from "react-slick";
import Image from "gatsby-plugin-sanity-image";
import "./style.css";
import { LeftArrow, RightArrow } from "./arrows";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  prevArrow: <LeftArrow />,
  nextArrow: <RightArrow />,
  arrows: true,
  dotsClass: "slick-dots slick-thumb",
  responsive: [
    {
      breakpoint: 640,
      settings: {
        centerPadding: "50px",
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        centerPadding: "50px",
        slidesToShow: 1,
      },
    },
  ],
};
export default function YourGuides({ data, show = "show" }) {
  return (
    <div
      className={`px-5 pt-4 pb-7 max-w-[1032px] mx-auto  md:pt-[55px] md:pb-[63px] ${show}`}
    >
      <p className="g-heading">{data?.title}</p>
      <div className=" mx-auto">
        <div className="g-quote">
          {data?.firstQuote}
          <span className="before:content-['\2014\200A']  before:text-black block text-[13px] leading-[21px] font-semibold text-right ">
            {data?.quote1}
          </span>
        </div>
      </div>
      <br />
      <div className="max-w-[1032px] mx-auto">
        <div className="g-slider">
          <Slider {...settings}>
            {data?.sliderImage?.map((item, index) => {
              return (
                <div key={index} className="flex justify-center  items-center">
                  <div className="mx-auto img_rounded overflow-hidden rounded-full h-[180px] w-[180px] mb-2 flex justify-center">
                    {item?.photo?.asset && (
                      <Image
                        asset={item?.photo?.asset}
                        hotspot={item.photo.hotspot}
                        crop={item?.photo.crop}
                        alt={item?.photo?.alt}
                        loading="eager"
                        width={180}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                  <div className="flex justify-center flex-col text-center text-[#323a44] mb-[2.5rem]">
                    <p className="text-[30px] leading-[39px] font-semibold mb-[1.2rem]">
                      {item.name}
                    </p>
                    <p className="text-base leading-[26px] italic font-semibold">
                      <span className="block">{item.guidance}</span>
                      <span className="block">{item.leader}</span>
                    </p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <br />

      <div className=" mx-auto">
        <div className="g-quote">
          {data?.secondQuote}
          <span className="before:content-['\2014\200A']  before:text-black  block text-[13px] leading-[21px] font-semibold text-right ">
            {data?.quote2}
          </span>
        </div>
      </div>
    </div>
  );
}
