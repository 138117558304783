import React from "react";

const OffsiteExcursion = ({ data }) => {
  return (
    <div
      className="relative block overflow-hidden box-border show"
      style={{
        backgroundImage: `linear-gradient(to bottom,rgba(192, 192, 192, 0.90),rgba(121, 121, 121, 0.90)),url(${data?.sectionBg.asset.url})`,
      }}
    >
      <div className="max-w-[1140px] w-full mx-auto block">
        <div className="flex flex-col flex-wrap justify-center min-h-[60vh]">
          <div className="w-full flex flex-col flex-wrap justify-start py-5 px-[10px]">
            {/* Heading */}
            <div className="w-full pt-[30px] pb-[10px]">
              <h1 className="text-xl sm:text-[40px] text-white text-center mb-3 font-bold tracking-[2px] uppercase">
                {data?.title}
              </h1>
            </div>
            {/* Content */}
            <div className="w-4/5 mx-auto pb-5 pt-5">
              <h2 className="text-xl sm:text-[40px] sm:leading-[60px] text-center font-bold text-white tracking-[1px]">
                {data?.offContent}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffsiteExcursion;
