import React from "react";
import { PortableText } from "@portabletext/react";
import Hero from "./hero";
import Logo from "../common/logo";
import FeaturedList from "../common/featuredlist";
import Review from "./Review";
import VacationExperience from "./VacationExperience";
import FreshMeal from "../accommodation/handcrafted_meal";
import ScheduleVacation from "../accommodation/schedulevacation";
import PanamaResort from "../accommodation/panamaresort";
import TranquilobayDifference from "../accommodation/how_tranquilobay_different";
import BreadCrumb from "../common/breadcrumb";
import CompaniesGuided from "../common/companiesGuided";
import RegenerativePledge from "../common/regenerativePledge";
import SliderAndContent from "../common/SliderAndContent";
import YourGuides from "../common/yourGuides";
import RegenrativeAdventure from "../birding/RegenerativeAdventure";
import DetailsPolicies from "../rates/Details_Policies";
import OffsiteExcursion from "../buyout/OffsiteExcursion";
import Detail from "../daytour/Detail";
import Instagram from "../common/Instagram";

export default function pageBuilder({ data, logo }) {
  return (
    <PortableText
      value={data}
      components={{
        // block: ({ value, children }) => {
        //   return <div className="bg-red-400 h-11"></div>;
        // },
        types: {
          heroSection: ({ value }) => {
            return (
              <>
                <Hero heroSection={value} h2P="pb-10" />
                <Logo logo={logo} />
              </>
            );
          },
          featureList: ({ value }) => {
            return <FeaturedList data={value} />;
          },
          reviewSection: ({ value }) => {
            return <Review data={value} show="show-up" />;
          },
          bookVacation: ({ value }) => {
            return <VacationExperience data={value} show="show-up" />;
          },
          freshMeal: ({ value }) => {
            return <FreshMeal data={value} url={true} show="show-up" />;
          },
          panamaVacation: ({ value }) => {
            return <ScheduleVacation data={value} url={true} show="show-up" />;
          },
          yourFvt: ({ value }) => {
            return (
              <PanamaResort
                data={value}
                minH="min-h-[60vh] sm:min-h-screen"
                bgUrl={`linear-gradient(to bottom,rgba(214, 214, 214, 0.90),rgba(192, 192, 192, 0.90)),url(${value?.sectionBG?.asset?.url})`}
                show="show-up"
                url={true}
              />
            );
          },
          tranqiulobay: ({ value }) => {
            return <TranquilobayDifference data={value} show="show-up" />;
          },
          breadcrumb: ({ value }) => {
            return (
              <div className="my-2">
                <BreadCrumb path={value} />;
              </div>
            );
          },
          companyGuided: ({ value }) => {
            return <CompaniesGuided data={value} />;
          },
          regenerative: ({ value }) => {
            return <RegenerativePledge data={value} />;
          },
          contentAndSlider: ({ value, index }) => {
            return (
              <SliderAndContent
                data={value}
                sliderId={`accommodation-${index}`}
                url={true}
              />
            );
          },
          guidesSection: ({ value }) => {
            return <YourGuides data={value} show="show-up" />;
          },
          birdingAdventure: ({ value }) => {
            return <RegenrativeAdventure data={value} show="show-up" />;
          },
          polices: ({ value }) => {
            return (
              <DetailsPolicies
                data={value}
                h1Size="text-3xl sm:text-[40px] text-secondary tracking-[2px]"
              />
            );
          },
          offsite: ({ value }) => {
            return <OffsiteExcursion data={value} />;
          },
          daytourList: ({ value }) => {
            return <Detail data={value} />;
          },
          instagram: ({ value }) => {
            if (value?.instagram) return <Instagram />;
          },
        },
      }}
    />
  );
}
